import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import ReactPaginate from "react-paginate";

import Layout from "../components/layout/layout";
import EventCard from "../components/sections/events/EventCard";

import SEO from "../components/seo";

const Events = () => {
  const data = useStaticQuery(graphql`
    {
      SWAContent {
        events {
          eventDate
          eventTitle
          eventSummary
          eventPhoto
          featured
          author {
            _id
            name
            imgURL
          }
          event {
            type
            title
            image
          }
          programme
          slug
          status
        }
      }
    }
  `);

  const [events, setEvents] = useState(data.SWAContent.events);
  // const [sortedEvents , setSortedEvents] = useState()
  const [filteredEvents, setFilteredEvents] = useState(data.SWAContent.events);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [pageNumber, setPageNumber] = useState(0);

  // if (filteredEvents) {
  //   console.log("filteredEvents", filteredEvents);
  // }
  // if (events) {
  //   console.log("events", events);
  // }

  // to be used for manual sort at browser but need to investigate why [events] is also being sorted by this?
  const sortedEvents = events.sort(
    (a, b) => new Date(b.eventDate) - new Date(a.eventDate)
  );
  // if (sortedEvents) {
  //   console.log("sorted: ", sortedEvents);
  // }

  const eventsPerPage = 50;
  const pagesVisited = pageNumber * eventsPerPage;

  const displayEvents = filteredEvents
    .slice(pagesVisited, pagesVisited + eventsPerPage)
    .map((event, i) => {
      return (
        <div key={i}>
          <EventCard event={event} />
        </div>
      );
    });

  const filterNames = [
    "All",
    ...new Set(
      events.map(e => {
        return e.programme;
      })
    ),
  ];

  const pageCount = Math.ceil(filteredEvents.length / eventsPerPage);
  // console.log("pageCount: ", pageCount);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleSearch = name => {
    if (name === "All") {
      setFilteredEvents(events);
      setSelectedFilter(name);
      setPageNumber(0);
    } else {
      const filteredList = events.filter(e => e.programme === name);
      setFilteredEvents(filteredList);
      setSelectedFilter(name);
      setPageNumber(0);
    }
  };

  return (
    <>
      {/* --- background pattern --- */}
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="Events from SWA"
          description="Our projects and activities are guided by 3 key pillars - Elderly, Community and Diversity. We run various recurring programmes that benefit our seniors, the general community and building towards greater diversity."
        />
        <div className="max-w-4xl px-2 mx-auto sm:w-4/5">
          <h1 className="text-swa-3">Events</h1>
          <p>
            View events or acitivities by selecting the appropriate date ranges
            or programmes
          </p>

          <div className="w-3/4 p-3 mx-auto mb-12 border border-gray-200 rounded-lg mt-9">
            <p className="mb-3 text-sm text-gray-700">Filter by programmes</p>
            <ul className="flex flex-wrap items-center justify-center space-x-3">
              {filterNames.map((filtername, i) => (
                <button
                  key={i}
                  onClick={() => handleSearch(filtername)}
                  onKeyDown={() => handleSearch(filtername)}
                  className={`px-3 py-1 text-sm font-bold uppercase ${
                    selectedFilter === filtername &&
                    `bg-swa-4 focus:outline-none focus:ring-2 focus:ring-swa-3 `
                  } bg-gray-200 rounded-lg cursor-pointer hover:bg-swa-4 mb-3 `}
                >
                  {filtername}
                </button>
              ))}
            </ul>
          </div>
          <div className="p-3 my-8 space-y-10 ">
            {displayEvents}
            {pageCount > 1 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Events;
