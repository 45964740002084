import React from "react";
import { Link } from "gatsby";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactHtmlParser from "react-html-parser";

const EventCard = ({ event }) => {
  // console.log("eventcard: ", event.event);
  return (
    <Link to={`/events/${event.slug}`}>
      <>
        <div className="relative flex flex-col w-full p-6 mx-auto my-12 overflow-visible transition duration-300 rounded-lg shadow-lg cursor-pointer md:flex-row md:w-11/12 md:items-start hover:bg-gray-100">
          <div className="w-full p-2 mx-auto md:mr-3 md:w-1/3">
            <div className="bg-white aspect-w-16 aspect-h-12">
              <img
                src={event.eventPhoto}
                alt={event.eventTitle}
                className="object-cover w-full h-full rounded"
              />
            </div>

            {/* {event.smallPhoto !== null && (
              <GatsbyImage
                image={getImage(event.smallPhoto)}
                alt={event.title}
              />
            )} */}
          </div>
          <div className="justify-between w-full p-2 md:flex md:flex-col">
            <div className="space-y-5 md:flex md:flex-col ">
              <div className="flex flex-col items-start">
                <h2 className=" text-swa-1">{event.eventTitle}</h2>
                <div className="w-full text-lg leading-snug text-gray-700 line-clamp-2">
                  {ReactHtmlParser(event.eventSummary)}
                </div>
                <div className="hidden my-2 btn-dark md:block">
                  Read more...
                </div>
                <div className="flex justify-between w-full p-2 mt-5 md:mt-0 ">
                  <div className="flex items-center justify-start my-2 space-x-2">
                    <div>
                      {event.author && (
                        <div className="flex items-center space-x-2 ">
                          {event.author.imgURL && (
                            <img
                              src={event.author.imgURL}
                              alt={event.author.name}
                              className="object-cover object-center w-8 h-8 mx-auto rounded-full"
                            />
                            // <GatsbyImage
                            //   image={getImage(event.author.authorPhoto)}
                            //   alt={event.author.name}
                            //   className="object-cover object-center w-8 h-8 mx-auto rounded-full"
                            // />
                          )}
                          <p className="text-xs text-gray-600">
                            {event.author.name}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    {event.programme !== null && (
                      <div className="px-2 py-1 mb-2 text-xs font-bold uppercase rounded-lg bg-swa-4">
                        {event.programme}
                      </div>
                    )}
                    {/* {event.published_at !== null && (
                      <p className="text-sm text-gray-600">
                        {event.published_at}
                      </p>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Link>
  );
};

export default EventCard;
